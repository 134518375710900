/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/news/press-releases/hear-app/'], ['en', 'https://www.hear.com/news/press-releases/hear-app/'], ['en-US', 'https://www.hear.com/news/press-releases/hear-app/'], ['en-CA', 'https://ca.hear.com/news/press-releases/hear-app/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-new-and-innovative-approach-to-changing-lives-through-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-new-and-innovative-approach-to-changing-lives-through-better-hearing",
    "aria-label": "a new and innovative approach to changing lives through better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A new and innovative approach to changing lives through better hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Toronto, October 29th,"), " hear.com – a global leader in the hearing aid industry – is happy to announce the launch of their official smartphone app for both iOS and Android devices. With this new innovation, hear.com aims to deliver online hearing care that is faster, easier, and more effective than ever before!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The key to hear.com’s ongoing success is their empathic care and comprehensive process. Their team of hearing aid experts and local Partner Providers take the time to listen to their customers, understand their needs, and provide a personalized solution. Throughout the entirety of the customer’s journey, constant communication is crucial to helping them adjust to their new hearing aids. And now, for the first time in hearing care history, hear.com customers can chat one-on-one with a personal hearing aid expert with the in-app chat feature at any time. The app allows customers to send text messages to a live hearing aid expert and get the help they need instantaneously. In addition to the ease and convenience of using the app, customers feel more comfortable going through the process, as the app reduces the need to call an expert every time they need assistance. There is no other company in the industry offering this high level of service quality and customer satisfaction."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "direct-access-to-hearcoms-services--anytime-anywhere",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#direct-access-to-hearcoms-services--anytime-anywhere",
    "aria-label": "direct access to hearcoms services  anytime anywhere permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Direct access to hear.com’s services – anytime, anywhere."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over the years, hear.com has changed thousands of lives through a process that includes more than just providing hearing aids. They’ve learned that to get the most out of hearing aids, customers need continual support and guidance as they get used to the new sounds. To this end, hear.com developed its new app to help maintain an open line of communication between their customers and their team of experts. The way it works is simple: existing hear.com customers are sent a text message with a personalized link to download the app onto their mobile device for free. Once the app is installed, they’ll be able to get live support from a hear.com hearing aid expert via the in-app chat feature. Moreover, customers can easily view their appointment details, schedule (or reschedule) appointments with the Partner Provider, and access additional resources related to their hearing health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hear-app-release-note.png",
    alt: "Screenshots of hear app",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-advice-at-your-fingertips",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-advice-at-your-fingertips",
    "aria-label": "expert advice at your fingertips permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert advice at your fingertips"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with the app can access the “Expert Advice” section and read relevant articles written by the experts themselves. For those who enjoy videos, the app comes with informative videos that address important topics related hearing loss and hearing aids. Both new hearing aid wearers and experienced users will find the hear.com app to be an incredibly useful tool as they embark on their journey to better hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The launch of the hear.com app is a major step forward in creating a more streamlined experience for customers and hearing care professionals. But the innovations don’t stop there! In the next few months, hear.com will develop more advanced features and add fresh content to the app, so stay tuned!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
